import React from "react"
import Container from "../../components/Container/Container";
import styles from "./SearchPage.module.scss";
import PageHeader from "../../components/PageHeader/PageHeader";
import {STATIC_TEXTS} from "../../resources/staticTexts";
import * as queryString from "query-string";
import Search from "../../components/Search/Search";
import {Link} from "gatsby";

const SearchPage = (props: any) => {

  const keyword = queryString.parse(props?.location?.search)?.keyword as string;

  const posts = props.pageContext.posts
    .filter(post => post?.title?.toLowerCase()?.includes(keyword?.toLowerCase()) || post?.tags?.some(tag => tag?.toLowerCase().includes(keyword?.toLowerCase())))

  return <Container className={styles.container}>
    <PageHeader header={STATIC_TEXTS.searchResults} description={`"${keyword}"`}/>
    <Search className={styles.search} id={"pageSearch"} value={keyword}/>
    <ul className={styles.results}>
      {posts.length ? posts.map(post =>
        <li>
          <Link to={`/blog/${post.slug}`} className={styles.thumbnail}>
            <img src={post.thumbnail?.file?.url} alt={post.title}/>
          </Link>
          <div className={styles.desc}>
            <Link to={`/page/${post.category?.slug}`}>
              <h4>{post.category?.title}</h4>
            </Link>
            <Link to={`/blog/${post.slug}`}>
              <h3>{post.title}</h3>
            </Link>
          </div>
        </li>) : <div className={styles.noResults}>{STATIC_TEXTS.noResults}</div>}
    </ul>
  </Container>;
};

export default SearchPage;
